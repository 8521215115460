import App from "next/app";
import Router from "next/router";
import Head from "next/head";
import withGA from "next-ga";
import NProgress from "nprogress";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/node";
import CookieConsent from "react-cookie-consent";

import { menuCategoriesApi, categoriesApi, userApi, cartApi } from "../services/api";
import { logout } from "../services/auth";

import { UserProvider } from "../components/UserContext";
import { CartProvider } from "../components/CartContext";
import { CategoriesProvider } from "../components/CategoriesContext";
import { MenuProvider } from "../components/MenuContext";
import { OutletProvider } from "../components/OutletContext";

import "react-toastify/dist/ReactToastify.css";
import "../assets/nprogress.css";
import "../assets/new.css";
// import "../assets/global.css";

Router.events.on("routeChangeStart", () => {
  NProgress.start();
});

Router.events.on("routeChangeComplete", () => NProgress.done());

Router.events.on("routeChangeError", () => NProgress.done());

Sentry.init({
  // Replace with your project's Sentry DSN
  dsn: "https://eaa42ce3c827462a8be0c9e09094fe11@sentry.io/2631849"
});

function MyApp({
  Component,
  pageProps,
  err,
  fetchedCategories,
  menuCategories,
  fetchedUser,
  fetchedCart,
  outlet
}) {
  if (fetchedUser) {
    Sentry.configureScope(function(scope) {
      scope.setUser({ username: fetchedUser.name });
    });
  }

  const modifiedPageProps = { ...pageProps, err };
  return (
      <UserProvider initial={fetchedUser}>
        <CookieConsent
        buttonText="Aceitar"
        buttonStyle={{ backgroundColor: "#e69e00"}}
        >Usamos cookies e outras tecnologias para melhorar sua experiência com nossos serviços e personalizar publicidade de acordo com seus interesses. Ao continuar navegando, você concorda com o uso.</CookieConsent>
        <CartProvider initial={fetchedCart}>
          <CategoriesProvider initial={fetchedCategories}>
            <MenuProvider initial={menuCategories}>
              <OutletProvider initial={outlet ? true : false}>
                <Head>
                  <meta charSet="UTF-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                  />
                  <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                  <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
                  <title key="title">Goods Br</title>
                  <link rel="shortcut icon" href="/favicon.png" />
                  <meta key="og-title" property="og:title" content="Goods Br" />
                  <meta
                    property="og:description"
                    content="Somos uma marca de artigos de decoração que nasceu da união de sonhos, sofisticação e paixão por fazer diferente."
                    key="description"
                  />
                  <meta
                    key="og-image"
                    property="og:image"
                    content="/background-home.jpg"
                  />
                </Head>

                <Component {...modifiedPageProps} />

                <div id="sac--digital--popup"></div>



                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                      (function (s, a, c, d, g, t) {
                        var hd = a.getElementsByTagName('body')[0];
                        var js = a.createElement('script');
                        js.async = 1;
                        js.src = c + d + t;
                        var cs = a.createElement('link');
                        cs.rel = 'stylesheet';
                        cs.href = c + g + t;
                        hd.appendChild(cs);
                        hd.appendChild(js);
                      })(window, document, '//api.sac.digital/popup/popup', '.js?v=', '.css?v=', '70a071f46db04c541fc8baac8e932133&s=2.0');
                    `
                  }}
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window,document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1048229382331034');
                    fbq('test_event_code', 'TEST96808');
                    `
                  }}
                />

                <img height="1" width="1" src="https://www.facebook.com/tr?id=1048229382331034&ev=PageView&noscript=1"/ >
                <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MBLZHRP" height="0" width="0" style={{display:"none", visibility:"hidden"}}></iframe></noscript>

                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover={false}
                />
              </OutletProvider>
            </MenuProvider>
          </CategoriesProvider>
        </CartProvider>
      </UserProvider>
  );
}

MyApp.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const { token, outlet, cart_token, souvenir } = appProps.pageProps;

  const fetchedCategories = await categoriesApi.all();
  const menuCategories = await menuCategoriesApi.menu();


  let fetchedUser = null;
  let fetchedCart = [];

  try {
    fetchedUser = token ? await userApi.get({ user_token: token }) : null;
    fetchedCart = token ?
                    await cartApi.get({ user_token: token })
                  : cart_token ? await cartApi.get({ user_token: token, cart_token: cart_token})
                    : [];
  } catch {
    fetchedUser = null;
    fetchedCart = [];
    logout();
  }

  return { ...appProps, fetchedCategories, fetchedUser, fetchedCart, outlet, menuCategories };
};

export default withGA("UA-59896248-1", Router)(MyApp);
